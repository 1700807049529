/* NotFoundPage.css */

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1; /* Light gray background */
  font-family: Arial, sans-serif;
}

.not-found-content {
  text-align: center;
}

h4 {
  font-size: 4rem;
  margin: 0;
  color: #333; /* Dark gray */
}

p {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #666; /* Medium gray */
}

